import React from 'react';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function CookiePolicy() {
  return (
    <Layout title="Cookie Policy">
      <SEO title="cookie policy anjandutta.com" />
      <p>This site uses cookies from Google to deliver its services and analyze traffic. 
        Your IP address and user-agent are shared with Google along with performance and security 
        metrics to ensure quality of service, generate usage statistics, and to detect and 
        address abuse.</p>    
    </Layout>
  );
}